@font-face {
  font-family: jost;
  src: url(./assets/font/Jost/static/Jost-Medium.ttf);
}
@font-face {
  font-family: just-sans;
  src: url(./assets/font/just_sans/JUST\ Sans\ Regular.otf);
}
@font-face {
  font-family: just-sans-bold;
  src: url(./assets/font/just_sans/JUST\ Sans\ ExBold.otf);
}
:root {
  --whiteColor: #fff;
  --titleColor: #21225f;
  --mainColor: #08a9e6;
  --themePurple: #895aef;
}
.navbar .navbar-nav {
  margin-left: auto;
  align-items: center;
}
.is-sticky + #enrollment {
  margin-top: 100px;
}
.navbar {
  background-color: var(--whiteColor);
  padding-right: 0;
  padding-top: 10px;
  padding-left: 0;
  padding-bottom: 10px;
}

.navbar .navbar-nav .nav-item a {
  font-family: jost, serif;
  font-size: 17px;
  color: var(--titleColor);
  position: relative;
  z-index: 1;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
}
.navbar .navbar-nav .nav-item a:hover,
.navbar .navbar-nav .nav-item a:focus,
.navbar .navbar-nav .nav-item a.active {
  color: #fff !important;
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
  border-radius: 25px;
}

.navbar.portfolioNav {
  color: #21225f;
  border-bottom: 1px solid #e0e0e0;
}
.navbar.portfolioNav .chainM {
  padding: 4px 12px;
  display: flex;
}
.navbar.portfolioNav .head {
  font-size: 30px;
  margin-left: 20px;
  margin-bottom: 0;
  color: #131313;
  font-weight: 500;
}
.navbar.portfolioNav .chainM .chain {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.navbar.portfolioNav .chainM .chain .ico {
  margin-right: 6px;
  max-width: 28px;
}
.pr {
  position: relative;
}
.sidebar-dark .nav-item .deleteIco {
  margin-left: auto;
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}
.sidebar-dark .nav-item:hover .deleteIco {
  display: inline-block;
}
.sec5 {
  padding: 70px 0;
}
.sec5 .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.sec5 .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  display: none;
}
.sec5 .videoImgCard {
  width: 100%;
}
.sec5 .videoImgCard .imgDiv img {
  width: 100%;
  height: auto;
}
.sec5 .swiper {
  z-index: 0;
}
.sec5 .swiper + div {
  z-index: 1000;
}
.sec5 .videoImgCard .title {
  padding: 20px 12px;
  font-size: 18px;
  font-weight: bold;
  min-height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sec5 .videoImgCard .imgDiv {
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.sec5 h1 {
  color: #050c26;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.sec5 .autoplay-progress {
  display: none;
}

.sec5 p {
  color: #8a8a8a;
  text-align: center;
}
.contact-form .opinionMain1 > label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;
}
.contact-form .opinionMain1 .opinionMain {
  display: flex;
}
.contact-form .opinionMain1 .opinionMain .opinion label {
  display: block;
}
.contact-form .opinionMain1 .opinionMain .opinion {
  text-align: center;
  margin-right: 10px;
}
.contact-form .walletInput {
  position: relative;
}
.contact-form .walletInput input:focus + .note {
  display: block;
}
.contact-form .walletInput .note {
  color: #cc0000;
  font-size: 12px;
  display: none;
  /* position: absolute; */
}
.contact-form .walletInput .isValid {
  font-size: 11px;
  position: absolute;
  right: 0;
  top: -17px;
  text-transform: uppercase;
}
.contact-form .walletInput .isValid i {
  margin-right: 3px;
  font-size: 13px;
}
.contact-form .walletInput .isValid .invalid {
  color: #cc0000 !important;
}
.contact-form .walletInput .isValid .valid {
  color: green !important;
}

.otpModalMain {
  padding: 10px;
  position: relative;
}
.otpModalMain .closeIcon {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  z-index: 111;
}
.otpModalMain .closeIcon i {
  font-size: 22px;
}
.otpModalMain .title {
  color: var(--themeBlue);
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}
.otpModalMain .desc {
  color: var(--bodyColor);
  margin-bottom: 30px;
  text-align: center;
}
.otpModalMain .otpInputMain {
  display: flex;
  justify-content: center;
}
.otpModalMain .otpInputMain input:not(:empty) {
  background-color: #fff;
}
.otpModalMain .otpInputMain input {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--titleColor);
  border: 1px solid var(--titleColor);
  border-radius: 7px;
  max-width: 63px !important;
  width: 100% !important;
  height: 70px;
  font-size: 34px !important;
}
.otpModalMain .otpInputMain input:focus {
  border: 1px solid var(--mainColor) !important;
  box-shadow: 0px 0px 0px 3px rgba(4, 165, 94, 0.12);
  outline: none !important;
}
.otpModalMain .teather span {
  font-weight: bold;
  font-size: 20px;
}
.otpModalMain .teather {
  font-weight: 500;
  margin-bottom: 2px;
}
.otpModalMain .noteTitle {
  color: red;
  font-weight: bold;
}
.otpModalMain .closed {
  color: red;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  line-height: 1.1;
}
.otpModalMain .desc1 {
  margin-bottom: 5px;
}
.otpModalMain .note {
  color: red;
  margin-bottom: 15px;
}
.copyToClip {
  position: relative;
  cursor: pointer;
}
.copyToClip:hover span {
  display: inline-block;
}
.copyToClip span {
  display: none;
  position: absolute;
  color: #fff;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 3px 12px;
  border-radius: 5px;
  background-color: #000;
  font-size: 12px !important;
  font-weight: 400;
  width: max-content;
}
.otpModalMain .walletBtn .default-btn {
  padding: 18px;
  font-size: 20px;
  width: 100%;
}
.color-dark {
  color: #555 !important;
}
.arrowDownIco {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
}
.form-group fieldset {
  border: none !important;
}
.pr11 {
  position: relative !important;
}
.form-group.phone input:focus {
  outline: none;
  border: none;
}
.form-group.phone input {
  color: var(--bodyColor) !important;
  border: none;
  background: transparent;
}
.form-group input {
  color: var(--bodyColor) !important;
}
.form-group input::-webkit-input-placeholder,
.form-group input:-ms-input-placeholder,
.form-group input::-ms-input-placeholder,
.form-group input::-webkit-input-placeholder {
  color: var(--bodyColor) !important;
}
.form-group svg,
.form-group i {
  color: var(--bodyColor) !important;
}
.form-group .dobPlaceholder {
  padding: 5px 15px;
  position: absolute;
  left: 0;
  top: 10px;
  background-color: #f6f6f6;
  bottom: 0;
  border-radius: 15px;
}
.sendMailBtn button:disabled {
  opacity: 0.8;
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
}
.sendMailBtn button {
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
  border-radius: 10px;
  padding: 6px 25px;
  font-weight: bold;
  color: white;
}
.videoM .modal-dialog {
  max-width: 750px !important;
}

.fileUpload-modal {
  margin-top: 20px;
  height: 150px;
  max-width: 250px;
}

.fileUpload-modal label {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  border: 2px dashed;
  border-color: #adadad;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.fileUpload-modal label img {
  width: 30px;
}

.fileUpload-modal label .img_sel {
  width: 100%;
}

.fileUpload-modal label input {
  display: none;
}
.userPostSec {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
.userPostSec .postCard {
  padding: 25px;
  padding-bottom: 30px;
  background-color: #f5f5f5;
  height: 100%;
  position: relative;
}
.userPostSec .postCard .imgDiv {
  /* max-height: 150px;
  overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 290px;
  overflow: hidden;
}
.userPostSec .postCard .desc p {
  margin-bottom: 0;
}
.userPostSec .postCard .desc {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid rgb(200, 200, 200);
}
.userPostSec .postCard .date {
  margin-left: 10px;
  color: #0000005e;
  font-size: 12px;
  flex-shrink: 0;
  margin-top: 5px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.text-uppercase {
  text-transform: uppercase;
}
.navbar .logoutDrop {
  position: relative;
}
.navbar .logoutDrop:hover .logout {
  display: block;
}
.navbar .logoutDrop .logout {
  position: absolute;
  top: 47.6px;
  left: 0;
  padding: 8px;
  text-align: center;
  width: 100%;
  display: none;
  cursor: pointer;
  border: 1px solid #8c8888;
  background: transparent;
  color: #000;
  border-radius: 0px 0px 30px 30px;
}
.mb-80 {
  margin-bottom: 80px;
}
.signalsPage {
  min-height: calc(100vh - 470px);
}
.signalsPage .broadcastMain {
  padding: 10px;
  border-radius: 16px;
  background: #f1f3f4;
  margin-top: 20px;
}
.signalsPage .broadcastMain .themeBtn {
  font-size: 13px;
  min-width: 180px;
  margin: auto;
}
.signalsPage .broadcastMain .masgM {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 15px;
  padding-top: 24px;
  margin-bottom: 3px;
  background: rgb(218 223 226);
  border-radius: 15px;
  position: relative;
}
.signalsPage .broadcastMain .masgM .date {
  position: absolute;
  top: 3px;
  right: 26px;
  font-size: 12px;
  z-index: 1;
}
.signalsPage .broadcastMain .masgM .msg {
  padding-bottom: 7px;
}
.signalsPage .broadcastMain .masgM .msg .title {
  font-size: 18px;
  border-top: 1px solid rgb(200, 200, 200);
  padding-top: 5px;
  margin-top: 10px;
}
.signalsPage .broadcastMain .masgM audio {
  width: 100%;
}
.signalsPage .broadcastMain .masgM iframe {
  /* width: 100%;
  height: auto !important; */
  width: 100%;
  height: auto;
  min-height: 240px;
}

.postBgLoad {
  min-height: calc(100vh - 470px);
}
.payHistoryPage {
  min-height: calc(100vh - 470px);
}
.navbar .logoutDrop .logout a:hover {
  color: var(--themePurple);
}
.navbar .logoutDrop .logout a {
  display: inline-block;
  font-size: 11px;
  margin: 0;
  width: 100%;
  color: #1b1b1b;
}
/* .descMainPost {
  min-height: 200px;
} */
.showPassInput {
  position: relative;
}
.showPassInput img {
  width: 30px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  padding: 5px;
  background-color: transparent;
  z-index: 11;
}
.loaderBtn i {
  font-size: 18px;
  top: 0 !important;
}
.addWalletText {
  font-size: 32px;
  text-align: center;
  padding: 40px 0;
  text-transform: uppercase;
  min-height: calc(100vh - 691px);
  display: flex;
  align-items: center;
  justify-content: center;
}
#__react-alert__ > div {
  z-index: 100000 !important;
}
.db {
  display: block;
}
.w-max-c {
  width: max-content;
}
.border-transparent {
  border: transparent !important;
}
.br-10 {
  border-radius: 10px !important;
}
.analytics-box-title {
  color: #8f9fb4;
  font-size: 13px;
  font-weight: 500;
}
.analytic-box-img {
  width: 35px;
  font-size: 35px;
}
.text-gray-800 {
  color: #5a5c69 !important;
}
.text-right {
  text-align: right;
}

.portfolioSec {
  font-family: just-sans, serif;
}
.portfolioSec h1 {
  font-size: 60px;
  color: #131313;
  line-height: 1.2;
}
.portfolioSec .portfolioM {
  max-width: 950px;
  margin: auto;
  text-align: center;
}
.portfolioSec .portfolioM .dashboardImg {
  margin-top: 40px;
}
.portfolioSec .default-btn {
  background-color: #131313;
  border-radius: 10px;
  text-transform: capitalize;
  border: 2px solid #131313;
}
.portfolioSec .default-btn:hover {
  color: #131313 !important;
  background-color: #fff;
}
/* .portfolioSec h1 span {
  color: #08a9e6;
  display: inline;
} */
.portfolioSec .section-title p {
  font-size: 18px;
  color: #6f6f6f;
  text-transform: capitalize;
}
.ConnectPage {
  display: flex;
  background: #21225f;
}
.ConnectPage .col1 {
  width: 50%;
  padding: 30px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ConnectPage .col1 .goBack {
  position: absolute;
  left: 30px;
  top: 30px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}

.ConnectPage .col2 {
  width: 50%;
  padding: 30px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #08a9e6;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  position: relative;
}
.ConnectPage .col2 .content {
  max-width: 500px;
}
.ConnectPage .col2 .content h3 {
  font-size: 62px;
  text-align: center;
  color: #fff;
  line-height: 1;
  margin-bottom: 50px;
}
.ConnectPage .col2 .content .connectBtn {
  background: #21225f;
  color: #fff;
  padding: 10px 20px;
  max-width: 370px;
  width: 100%;
  margin: auto;
  border-radius: 25px;
  font-size: 14px;
  text-transform: capitalize;
}
.ConnectPage .col2 .content .connectBtn:hover {
  background: #fff;
  color: #21225f;
}
.ConnectPage .col2 .star1 {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 80px;
}
.ConnectPage .col2 .star2 {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 80px;
}
.portfolioDashboard {
  font-family: just-sans, serif;
}
.portfolioDashboard #content-wrapper {
  /* background-color: #F4F4F4 !important; */
}
.portfolioDashboard .sidebar {
  /* background-color: #21225F ; */
}
.portfolioDashboard .sidebar-Link {
  background-color: #fff;
  color: #21225f;
}
.portfolioDashboard .sidebar-Link.active {
  background-image: unset !important;
  color: #21225f !important;
  background-color: #fff;
}
.portfolioDashboard .portfolioPage {
  padding: 20px;
}
.portfolioDashboard .portfolioPage .portCard {
  background: #fff;
  border-radius: 13px;
  overflow: hidden;
  height: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
}
.portfolioDashboard .portfolioPage .portCard .head {
  padding: 13px 20px;
  font-size: 21px;
  font-weight: bold;
  color: #131313;
  border-bottom: 1px solid #d9d9d9;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm {
  padding: 25px;
  color: #131313;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .titleRow .name {
  font-weight: bold;
  color: #022b47;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .profileRow.active {
  border: 1px solid #08a9e6;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .profileRow {
  display: flex;
  align-items: center;
  background: #f0f2f5;
  padding: 7px 10px;
  border-radius: 6px;
  margin-bottom: 7px;
  cursor: pointer;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .profileRow .logo {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .profileRow .title {
  font-weight: bold;
  color: #022b47;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .profileRow .amount {
  font-size: 12px;
  color: #a1a1a1;
}
.portfolioDashboard .portfolioPage .portCard .addAccount {
  color: #08a9e6;
  font-weight: bold;
  margin-top: 10px;
}
.portfolioDashboard .portfolioPage .portCard .amountRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.portfolioDashboard .portfolioPage .portCard .amountRow .pRow {
  display: flex;
  align-items: center;
  color: #8f8f8f;
  font-weight: bold;
  margin-bottom: 4px;
}
.portfolioDashboard .portfolioPage .portCard .amountRow .pRow .imgDiv {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
}
.portfolioDashboard .portfolioPage .portCard .amountRow .amount {
  font-size: 30px;
  font-weight: 500;
}
.portfolioDashboard .portfolioPage .portCard .amountRow .amountSm {
  color: #9f9f9f;
  font-weight: 500;
}
.portfolioDashboard .portfolioPage .portCard .r2 {
  display: flex;
  margin-top: 25px;
}
.portfolioDashboard .portfolioPage .portCard .r2 .profiltCard {
  width: 50%;
  margin-right: 10px;
  padding: 20px;
  border: 2px solid #08a9e6;
  border-radius: 15px;
}
.portfolioDashboard .portfolioPage .portCard .r2 .title {
  color: #8f8f8f;
}
.portfolioDashboard .portfolioPage .portCard .r2 .profiltCard .amount {
  font-size: 23px;
  color: #08a9e6;
  font-weight: bold;
}
.portfolioDashboard .portfolioPage .portCard .r2 .perc {
  color: #08a9e6;
}
.portfolioDashboard .portfolioPage .portCard .r2 .performerCard {
  width: 50%;
  margin-left: 10px;
  padding: 20px;
  /* border: 2px solid #08A9E6; */
  border-radius: 15px;
  background: #f7f7fd;
}
.portfolioDashboard .portfolioPage .portCard .r2 .performerCard .currency {
  font-size: 25px;
  color: #022b47;
  font-weight: bold;
  align-items: center;
  display: flex;
}
.portfolioDashboard .portfolioPage .portCard .r2 .performerCard .currency img {
  width: 25px;
  margin-right: 5px;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .balanceM {
  padding: 25px;
  background: #f4f4f4;
  border-radius: 8px;
  height: 100%;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .balanceM .title {
  color: #9f9f9f;
  font-weight: 500;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .balanceM .balance {
  font-size: 22px;
  font-weight: 500;
  color: #131313;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .tokenMain {
  max-height: 300px;
  overflow-y: auto;
  /* padding-right: 20px; */
  color: #131313;
}
.portfolioDashboard .portfolioPage .portCard .myPortForm .tokenMain .tokenRow {
  padding: 6px 20px;
  background: #f4f4f4;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}
.portfolioDashboard
  .portfolioPage
  .portCard
  .myPortForm
  .tokenMain
  .tokenRow
  .coin {
  display: flex;
  align-items: center;
  color: #022b47;
  font-size: 13px;
  font-weight: 500;
}
.portfolioDashboard
  .portfolioPage
  .portCard
  .myPortForm
  .tokenMain
  .tokenRow
  .coin
  .logo {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  /* overflow: hidden; */
  margin-right: 5px;
}
.portfolioDashboard
  .portfolioPage
  .portCard
  .myPortForm
  .tokenMain
  .tokenRow
  .coin
  .logo
  img {
  max-height: 100%;
  object-fit: cover;
}
.portfolioDashboard
  .portfolioPage
  .portCard
  .myPortForm
  .tokenMain
  .tokenRow
  .amount {
  text-align: right;
  color: #131313;
  font-weight: 500;
}
.portfolioDashboard
  .portfolioPage
  .portCard
  .myPortForm
  .tokenMain
  .tokenRow
  .usd {
  color: #c7c7c7;
  font-size: 11px;
  text-align: right;
}
.portfolioDashboard .portfolioPage .selectChain {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}
.portfolioDashboard .portfolioPage .selectChain .sBtn.active {
  background-color: #131313;
  color: #fff;
}
.portfolioDashboard .portfolioPage .selectChain .sBtn {
  padding: 7px 20px;
  border-radius: 12px;
  background-color: #fff;
  color: #000;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
}
.portfolioDashboard .portfolioPage .selectChain .sBtn:hover {
  background-color: #131313;
  color: #fff;
}
.portfolioDashboard .portfolioPage .selectChain .sBtn.active:hover {
  background-color: #fff;
  color: #000;
}
.portfolioDashboard .portfolioPage .borderCard {
  border: 1px solid #e0e0e0;
  border-radius: 18px;
  padding: 20px;
}
.portfolioDashboard .portfolioPage .borderCard table .date {
  min-width: 150px;
}

.portfolioDashboard .overviewNavigation {
  background-color: #131313;
  border-radius: 13px;
  padding: 10px;
  display: flex;
  width: max-content;
  margin-bottom: 20px;
}
.portfolioDashboard .overviewNavigation .oBtn {
  color: #fff;
  font-size: 16px;
  padding: 7px 16px;
  border-radius: 8px;
  background-color: transparent;
  border: none;
}
.portfolioDashboard .overviewNavigation .oBtn.active {
  background-color: #fff;
  color: #131313;
}
.portfolioDashboard .portfolioPage table th,
.portfolioDashboard .portfolioPage table td {
  padding: 15px;
  vertical-align: middle;
}
.portfolioDashboard .portfolioPage table th {
  border-bottom-color: #e0e0e0 !important;
}
.portfolioDashboard .portfolioPage .perforforCard {
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  max-width: 550px;
  width: 100%;
}
.portfolioDashboard .portfolioPage .perforforCard .pNav {
  display: flex;
}
.portfolioDashboard .portfolioPage .perforforCard .pNav button {
  width: 50%;
  border-bottom: 1px solid #e0e0e0;
  padding: 13px;
  color: #131313;
  background-color: transparent;
}
.portfolioDashboard .portfolioPage .perforforCard .pNav button.active {
  border-bottom: 1px solid #131313;
}
.portfolioDashboard .portfolioPage .perforforCard .r2 {
  display: flex;
  font-size: 12px;
  color: #606060;
  border-bottom: 1px solid #e0e0e0;
}
.portfolioDashboard .portfolioPage .perforforCard .r2 .col1 {
  width: 54%;
  padding: 5px 13px;
  border-right: 1px solid #e0e0e0;
}
.portfolioDashboard .portfolioPage .perforforCard .r2 .col2 {
  width: 23%;
  padding: 5px 13px;
  border-right: 1px solid #e0e0e0;
  min-width: max-content;
}
.portfolioDashboard .portfolioPage .perforforCard .r2 .col3 {
  width: 23%;
  padding: 5px 13px;
  min-width: max-content;
}
.portfolioDashboard .portfolioPage .perforforCard .r3 {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #131313;
}
.portfolioDashboard .portfolioPage .perforforCard .r3 .col1 {
  width: 54%;
  padding: 16px 13px;
  display: flex;
  align-items: center;
}
.portfolioDashboard .portfolioPage .perforforCard .r3 .col1 .ico {
  margin-right: 7px;
}
.portfolioDashboard .portfolioPage .perforforCard .r3 .col2 {
  width: 23%;
  padding: 16px 13px;
  min-width: max-content;
}
.portfolioDashboard .portfolioPage .perforforCard .r3 .col3 {
  width: 23%;
  padding: 16px 13px;
  min-width: max-content;
}
.overflow-x-auto {
  overflow-x: auto;
}

.broadcastModal .broadCastIco {
  max-width: 380px;
  margin: auto;
  /* margin-bottom: 20px; */
}
.broadcastModal .gradient-head {
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #0000;
  text-transform: capitalize;
}
.broadcastModal .broadTitle {
  font-size: 18px;
  color: #1b1b1b;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 400;
}
.broadcastModal .broadBtnMain {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.broadcastModal .broadBtnMain .themeBtn:first-child {
  margin-right: 15px;
}
.broadcastModal .broadBtnMain .themeBtn i {
  margin-right: 10px;
}
.broadcastModal .broadBtnMain .themeBtn {
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
  border-radius: 30px;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  min-width: 228px;
}
.broadcastModal .broadBtnMain .themeBtn:hover {
  background: transparent;
  outline: 1px solid #140751;
  color: #140751;
}
.broadcastModal .broadBtnMain .borderBtn {
  outline: 1px solid #140751;
  background: transparent;
  border-radius: 30px;
  color: #140751;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  min-width: 228px;
  text-transform: uppercase;
}
.broadcastModal .broadBtnMain .borderBtn:hover {
  color: #fff;
  outline: unset;
  background-color: var(--titleColor);
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
}

.couseModal {
  padding-top: 26px;
}
.couseModal .mainBtn {
  display: flex;
  justify-content: space-between;
}
.couseModal .mainBtn .groupBtn:last-child {
  margin-right: 0px;
}
.couseModal .mainBtn .groupBtn.active {
  background: url(./images/homepage/folderBeige.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #131313;
}
.couseModal .mainBtn .groupBtn {
  margin-right: 10px;
  background: url(./images/homepage/folderPurple.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background: #229ed9; */
  color: #fff;
  padding: 20px 15px;
  padding-top: 27px;
  /* border-radius: 15px; */
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.couseModal .mainBtn.advanceTypeMain .groupBtn {
  font-size: 13px;
  padding: 7px 10px;
}
.couseModal .mainBtn.advanceTypeMain {
  padding-top: 10px;
  border-top: 1px solid #eeeeee;
}
.couseModal .linkM {
  margin-top: 10px;
}

.couseModal .linkM a {
  word-break: break-all;
  color: #05b4ef;
  line-height: 1.2;
  font-size: 14px;
}
.couseModal .linkM a:hover {
  text-decoration: underline;
}
.couseModal .linkM .link {
  width: 100%;
  padding: 10px;
  border-radius: 0px;
  background-color: #fff;
  margin: 5px 0;
  color: #000;
  font-size: 18px;
  border-bottom: 2px solid #96969678;
}
.couseModal .videoMain {
  max-height: 400px;
  overflow-y: auto;
  margin: 20px 0;
  padding-right: 8px;
}
.couseModal .videoMain .video {
  margin-bottom: 12px;
  background-color: #f5f5f5;
}

.couseModal .videoMain::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.couseModal .videoMain::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.couseModal .videoMain::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.a233nb {
  padding: 15px 25px;
  background-color: rgba(211, 211, 211, 0.313);
  border-radius: 5px;
}
.portfolio-modal .form-label {
  color: #000;
  font-weight: 700;
}
.atx-modal-tabs {
  padding: 6px;
  background-color: #fbfbfb;
  border-radius: 5px;
  margin: 15px 0;
}
.atx-modal-tabs button {
  width: calc(100% / 2);
  color: #022b47;
  font-weight: 700;
  /* margin-top: 10px; */
}
.atx-modal-tabs button.active {
  background-color: #131313;
}
.themeBtn1 {
  background-color: #131313;
  color: #fff;
  font-weight: bold;
}
.portfolioPage .alltimeRow {
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  margin-bottom: 20px;
}
.portfolioPage .alltimeRow .head {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.portfolioPage .alltimeRow .card {
  padding: 20px;
  border-radius: 12px;
  background: #f4f4f4;
  border: none;
  height: 100%;
}
.portfolioPage .alltimeRow .card .atp {
  color: #000;
  font-weight: 500;
  font-size: 17px;
}
.portfolioPage .alltimeRow .card .cry {
  display: flex;
  align-items: center;
  margin: 8px 0;
  text-transform: capitalize;
}
.portfolioPage .alltimeRow .card .cry img {
  margin-right: 8px;
}
.portfolioPage .alltimeRow .performanceRow {
  display: flex;
  justify-content: space-between;
}
.portfolioPage .alltimeRow .performanceRow .totalCol {
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  max-width: 260px;
  width: 100%;
  margin-right: 10px;
  text-align: center;
}
.portfolioPage .alltimeRow .performanceRow .totalCol .head1 {
  border-bottom: 1px solid #e0e0e0;
  padding: 13px;
  color: #131313;
  background-color: transparent;
  text-align: center;
  justify-content: center;
}
.portfolioPage .alltimeRow .performanceRow .totalCol h3 {
  font-size: 26px;
  padding: 24px 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.btn:focus {
  box-shadow: none !important;
}
.p-00 {
  padding: 0 !important;
}

.bg-gray1 {
  background: #e3e5e6;
}
.courseModal {
  padding-left: 0 !important;
}
.courseModal .modal-dialog {
  max-width: 600px !important;
}
.subscriptionNav {
  background: #1b1b1b;
  padding: 7px;
  border-radius: 26px;
  display: flex;
  flex-wrap: wrap;
  width: max-content;
  margin: auto;
}
.subscriptionNav .link {
  font-family: jost, serif;
  color: #fff;
  font-weight: 400;
  padding: 3px 15px;
  border-radius: 25px;
  cursor: pointer;
}
.subscriptionNav .link.active {
  background: linear-gradient(90deg, #384ef4 0%, #b060ed 100%);
}

@media screen and (max-width: 1200px) {
  .navbar .logoutDrop {
    position: relative;
    width: max-content;
    margin: auto;
  }
}
@media screen and (max-width: 992px) {
  .portfolioPage .alltimeRow .performanceRow {
    flex-direction: column;
  }
  .portfolioPage .alltimeRow .performanceRow .totalCol {
    margin-bottom: 20px;
  }
  .ConnectPage {
    flex-direction: column;
  }
  .ConnectPage .col1 {
    min-height: 40vh;
    width: 100%;
    padding-bottom: 0;
  }
  .ConnectPage .col1 .imgDiv {
    max-width: 320px;
  }
  .ConnectPage .col2 {
    width: 100%;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0;
    min-height: 60vh;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .ConnectPage .col2 .star1 {
    width: 60px;
  }
  .ConnectPage .col2 .star2 {
    width: 60px;
  }
}
@media screen and (max-width: 768px) {
  .portfolioDashboard .portfolioPage .portCard .myPortForm .tokenMain {
    margin-top: 20px;
  }
  .sidebar-dark .nav-item .nav-link.active .deleteIco {
    position: relative;
    display: inline-block;
    top: 0;
    font-size: 12px;
  }
  .subscriptionNav {
    width: auto;
    align-items: flex-start;
  }
  .subscriptionNav .link {
    width: 50%;
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .subscriptionNav .link {
    font-size: 14px;
    padding: 3px 12px;
  }
  .otpModalMain {
    padding: 10px 0;
  }
  .couseModal {
    padding-top: 36px;
  }
  .couseModal .mainBtn {
    /* flex-wrap: wrap; */
  }
  .couseModal .mainBtn .groupBtn {
    font-size: 12px;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 10px;
    background-size: cover;
    padding: 4px 5px;
    padding-top: 10px;
  }
  .couseModal .mainBtn .groupBtn.active {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .navbar.portfolioNav .chainM .chain {
    font-size: 12px;
  }
  .portfolioSec h1 {
    font-size: 36px;
  }
  .portfolioPage .alltimeRow {
    padding: 0;
  }
  .portfolioDashboard .portfolioPage .borderCard {
    padding-left: 12px;
    padding-right: 12px;
  }
  .portfolioPage .alltimeRow .head .left {
    margin-bottom: 10px;
  }
  .portfolioPage .alltimeRow .head {
    margin-bottom: 10px;
  }
  .portfolioDashboard .portfolioPage .perforforCard .pNav button {
    font-size: 13px;
  }
  .portfolioDashboard .portfolioPage .perforforCard .r2 .col1 {
    width: 46%;
  }
  .portfolioDashboard .portfolioPage .perforforCard .r2 .col2 {
    width: 27%;
  }
  .portfolioDashboard .portfolioPage .perforforCard .r2 .col3 {
    width: 27%;
  }
  .portfolioDashboard .portfolioPage .perforforCard .r3 .col1 {
    width: 46%;
    padding: 10px 7px;
    font-size: 13px;
  }
  .portfolioDashboard .portfolioPage .perforforCard .r3 .col2 {
    width: 27%;
    padding: 10px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .portfolioDashboard .portfolioPage .perforforCard .r3 .col1 .ico {
    width: 20px;
    margin-right: 5px;
  }
  .portfolioDashboard .portfolioPage {
    padding: 12px;
  }
  .sidebar .sideInner {
    margin: 12px 0 12px 12px !important;
  }
  .portfolioDashboard .overviewNavigation .oBtn {
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .portfolioDashboard .portfolioPage .borderCard table {
    font-size: 13px;
  }

  .portfolioDashboard .portfolioPage .perforforCard .r3 .col3 {
    width: 27%;
    padding: 10px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .portfolioPage .alltimeRow .performanceRow .totalCol h3 {
    padding: 10px;
  }
  .navbar.portfolioNav .head {
    display: none;
  }
  .ConnectPage .col2 .content h3 {
    font-size: 35px;
    margin-top: 10px;
  }

  .ConnectPage .col2 {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 100px;
  }
  .broadcastModal .broadBtnMain {
    flex-direction: column;
  }
  .broadcastModal .broadBtnMain .themeBtn:first-child {
    margin: 0;
    margin-bottom: 10px;
  }
  .broadcastModal .broadBtnMain .themeBtn {
    font-size: 17px;
  }
  .broadcastModal .broadTitle {
    font-size: 20px;
  }
  .navbar-brand {
    max-width: 150px;
  }
}
@media screen and (max-width: 350px) {
  .couseModal .mainBtn {
    /* flex-direction: column; */
  }
  .couseModal .mainBtn .groupBtn {
    margin-bottom: 6px;
    padding: 10px;
    font-size: 11px !important;
  }
}
